import { Paths } from "../../../routes/Paths";

export interface BPHeaderLink {
  title: string;
  link: string;
}

export const Links: BPHeaderLink[] = [
  {
    title: "Nosotros",
    link: Paths.US,
  },
  {
    title: "Estudios",
    link: Paths.CATALOG,
  },
  {
    title: "Empresas",
    link: Paths.CONTACT,
  },
  /*{
    title: 'Resultados',
    link: Paths.RESULTS,
  },*/ {
    title: "Pruebas Covid-19",
    link: Paths.COVID_TESTS,
  } /*{
    title: 'Membresías',
    link: Paths.MEMBERSHIPS,
  }, */,
  // {
  //   title: 'Cotización',
  //   link: Paths.BUDGETIZER,
  // },
];
