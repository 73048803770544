import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { Study } from "../../../../types";
import FlexBox from "../../../common/FlexBox/FlexBox";
import StrikeText from "../../../common/StrikeText/StrikeText";
import BudgetizeButton from "../../../common/BudgetizeButton/BudgetizeButton";

export interface ResultCardProps {
  name: string;
  iconUrl: string;
  iconAlt?: string;
  study: Partial<Study>;
  onResultClick?: (sku: string) => void;
}

const Result: FC<ResultCardProps> = ({
  study,
  iconUrl,
  iconAlt,
  onResultClick,
}) => {
  const icon = iconUrl || "icons/resp.png";

  return (
    <FlexBox
      sx={{
        boxShadow: "2px 2px 14px rgba(0, 0, 0, 0.1)",
        borderRadius: 1,
        alignItems: "center",
        padding: "16px 24px 24px 24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 3,
          flexShrink: 3,
          background: "white",
        }}
      >
        <Box
          sx={{ cursor: "pointer", flex: 1, maxWidth: 64 }}
          onClick={() => onResultClick?.(study.sku!)}
        >
          <img src={icon} width="100%" alt={iconAlt} />
        </Box>

        <Box sx={{ marginLeft: "24px", flex: 2 }}>
          <Typography
            style={{
              cursor: "pointer",
              overflow: "hidden",
              paddingBottom: 4,
              fontSize: 20,
            }}
            data-testid={`result-name-${study.name}`}
            onClick={() => onResultClick?.(study.sku!)}
            flex={2}
            variant="body1"
          >
            {study.name}
          </Typography>
          <Typography
            color="light"
            variant="caption"
            sx={{ fontSize: 16, lineHeight: 0 }}
          >
            {study?.methodology}
          </Typography>
          <Box sx={{ columnGap: 1, display: "flex" }}>
            {!!study.price?.isDiscount && (
              <StrikeText
                color="grey"
                sx={{ fontSize: "18px", paddingTop: 0.2 }}
              >
                $ {study?.price?.referencePrice}
              </StrikeText>
            )}
            <Typography
              color="secondary"
              sx={{ fontSize: "20px", paddingBottom: 1.1 }}
            >
              $ {study?.price?.value}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", columnGap: 3 }}>
            <Button
              onClick={() => onResultClick?.(study.sku!)}
              fullWidth
              variant="contained"
              color="primary"
            >
              Detalles
            </Button>
            {/* <BudgetizeButton fullWidth study={study} /> */}
          </Box>
        </Box>
      </Box>
    </FlexBox>
  );
};

export default Result;
